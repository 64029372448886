.raphitaTag {
  font-size: 14px;
  list-style: none;
  display: inline-block;
  height: auto;
  padding: 4px 8px;
  font-size: 10px;
  line-height: 18px;
  white-space: nowrap;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.3s;
  margin-right: 8px;
  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #212529;
  }
  &:last-child {
    margin: 0;
  }
}
