.navigation__navbar {
  border-bottom: 1px solid #dee2e6;
  &.navbar {
    padding: 20px 0;
  }
  .__nav {
    column-gap: 36px;
  }
  .navbar-toggler {
    border: 0;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
}
