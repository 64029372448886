.contactUs__wrapper {
  .__header {
    // background: url('../../../../assets/contactUs.webp');
    // background-position: center right;
    // background-size: contain;
    // background-repeat: no-repeat;
    width: 100%;
    display: flex;
    // height: 755px;
    align-items: center;
    justify-content: space-between;
  }
  .__title {
    .__content {
      max-width: 275px;
    }
    .__content > div {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .__image {
    overflow: hidden;
    img {
      // width: 100%;
      // height: auto;
      object-fit: cover;
      object-position: left top;
    }
  }
  .__category {
    background: #f8f9fa;
    padding-block: 120px;
    .categoryCard__wrapper {
      max-width: 1280px;
      margin: auto;
      justify-content: center;
      width: 100%;
    }
  }
}
