.aboutUs__wrapper {
  padding-top: 120px;
  .__title {
    position: relative;
    margin-bottom: 80px;
    img {
      display: block;
      max-width: 100%;
      height: auto;
      width: 100%;
      z-index: -1;
    }
    .floatingCard__wrapper {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    .floating__card {
      width: 100%;
      padding: 40px 32px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: flex-start;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    }
  }
  .__content {
    padding-top: 80px;
    padding-bottom: 120px;
  }
  .ourClient__wrapper {
    margin-bottom: 160px;
  }
  @media screen and (max-width: 1199px) {
    .__title {
      .floating__card {
        padding: 32px 28px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .__title {
      height: 576px;
      margin-bottom: 120px;
      .floatingCard__wrapper {
        transform: translateY(10%);
      }
    }
  }
}
