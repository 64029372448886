// Here you can add other styles
// html,
// body {
//   font-family: 'Inter', sans-serif;
// }
a {
  text-decoration: none;
}
.divider {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
  box-sizing: border-box;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.px-20 {
  padding-inline: 80px;
}
.text-sm {
  font-size: 14px;
  line-height: 150%;
}
.headerGradient {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    background: linear-gradient(180deg, #c8dbff 0%, rgba(217, 217, 217, 0) 100%);
    left: 0px;
    top: 0px;
    height: 200px;
    display: block;
    z-index: -1;
  }
}
.pointer {
  cursor: pointer;
}
