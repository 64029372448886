.productCard {
  width: 100%;
  cursor: pointer;
  padding-bottom: 20px;
  .img_wrapper {
    height: 248px;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
