.react-carousel {
  .carousel :is(.image-wrapper, img) {
    height: 100%;
  }
  .carousel .slide img {
    object-fit: contain;
    object-position: center center;
  }
  .carousel .thumbs-wrapper {
    margin: 0;
    margin-top: 28px;
  }
  .carousel .thumbs {
    margin-bottom: 0;
    padding-left: 0;
  }
  .carousel .control-arrow {
    background-color: #ebedef;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #212529;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #212529;
  }
}
