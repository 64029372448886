.certification__section {
  position: relative;
  .bg {
    position: absolute;
    background-color: #f8f9fa;
    height: 425px;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }
  .certification__wrapper {
    align-items: flex-end;
    padding-bottom: 80px;
    .certification__logo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 14px 28px;
      margin-bottom: 70px;
    }
    .carousel-image {
      max-width: 418px;
      width: 100%;
      height: 625px;
      margin-inline: auto;
    }
  }
  @media screen and (max-width: 991px) {
    .bg {
      height: 751px;
    }
    .certification__wrapper {
      flex-direction: column-reverse;
    }
  }
}
