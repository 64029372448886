.productPage__layout {
  position: relative;
  padding-top: 80px;
  padding-bottom: 168px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    background: linear-gradient(180deg, #c8dbff 0%, rgba(217, 217, 217, 0) 100%);
    left: 0px;
    top: 0px;
    height: 200px;
    display: block;
    z-index: -1;
  }
  .sidebarCategory {
    display: flex;
    flex-flow: column wrap;
    row-gap: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    border-radius: 6px;
    background: #fff;
    &__category,
    &__brand,
    &__tag {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 16px;
      .divider {
        margin-top: 12px;
        margin-bottom: 20px;
      }
    }
    .tagList {
      display: flex;
      flex-flow: row wrap;
      gap: 12px 8px;
      flex-grow: 1;
      flex-shrink: 0;
    }
    .link-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .sidebarCategory {
      max-height: 399px;
      gap: 4px;
      &__category,
      &__brand {
        max-width: 226.67px;
        width: 100%;
      }
      &__tag {
        max-width: 457.33px;
      }
    }
  }
}
