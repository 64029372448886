.galleryProduct__wrapper {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    background: #94d5f01a;
    left: 0px;
    top: 0px;
    height: 600px;
    display: block;
    z-index: -1;
  }
  .galleryProduct {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 531px));
    grid-auto-flow: dense;
    gap: 24px 32px;
    padding-top: 86px;
    padding-bottom: 52px;
    margin-bottom: 200px;
    margin-inline: 60px;
    .gallery {
      position: relative;
    }
    .gallery:hover .overlay-img {
      opacity: 1;
    }
    .overlay-img {
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(14, 14, 14, 0) 47.99%, rgba(14, 14, 14, 0.7) 100%);
      width: 100%;
      transition: 0.5s ease;
      opacity: 0;
      color: #f8f9fa;
      font-size: 20px;
      font-weight: 500;
      padding: 20px;
    }
    .gallery1 {
      grid-row: 1 / span 1;
    }
    .gallery2 {
      grid-row: 1 / span 2;
    }
    .gallery3 {
      grid-row: 1;
      grid-column: 3;
    }
    .gallery4 {
      grid-row: 2;
      grid-column: 3;
    }
    .gallery5 {
      grid-row: 2 / span 2;
    }
    .gallery6 {
      grid-row: 3;
      grid-column: 2 / span 2;
    }
    & > div > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(auto-fit, minmax(auto, 342px));
      .gallery6 {
        grid-column: 2;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .galleryProduct {
      margin-inline: 40px;
    }
  }
  @media screen and (max-width: 576px) {
    &::before {
      height: 460px;
    }
    .galleryProduct {
      margin-inline: 24px;
      padding-top: 67px;
      gap: 13px;
    }
  }
}
