.productPage__wrapper {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    background: linear-gradient(180deg, #c8dbff 0%, rgba(217, 217, 217, 0) 100%);
    left: 0px;
    top: 0px;
    height: 200px;
    display: block;
    z-index: -1;
  }
  .titleSection {
    padding-top: 80px;
    text-align: center;
    margin-bottom: 100px;
    h1 {
      margin-bottom: 12px;
    }
  }
  .divider {
    margin: 12px 0 32px;
  }
  .brandSection {
    margin-bottom: 72px;
    &__img {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px 40px;
    }
    img {
      cursor: pointer;
      max-width: 100%;
      min-height: 32px;
    }
  }
  .categorySection {
    display: flex;
    flex-direction: column;
    margin-bottom: 72px;
  }
  .productSection {
    margin-bottom: 120px;
    .productCard__wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 32px 0px;

      .productCard {
        max-width: 248px;
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .productSection {
      .row > *:nth-last-child(-n + 2) {
        display: none;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .brandSection__img {
      justify-content: center;
    }
  }
}
