.categoryCard__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  .categoryCard {
    cursor: pointer;
    row-gap: 20px;
    color: #29abe1;
    background: #f8f9fa;
    border: 1px solid #29abe1;
    border-radius: 28px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    max-width: 222.4px;
    width: 100%;
  }
  h5 {
    max-width: 141px;
  }
  @media screen and (max-width: 1399px) {
    .categoryCard {
      max-width: 190.4px;
    }
  }
  @media screen and (max-width: 1199px) {
    .categoryCard {
      max-width: 156.8px;
    }
  }
  @media screen and (max-width: 991px) {
    .categoryCard {
      max-width: 208px;
      &:nth-last-child(-n + 2) {
        max-width: 328px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .categoryCard {
      max-width: 149.33px;
      &:nth-last-child(-n + 2) {
        max-width: 240px;
      }
    }
  }
  @media screen and (max-width: 575px) {
    gap: 16px;
    justify-content: space-around;
    .categoryCard {
      max-width: 149.33px;
      &:nth-last-child(-n + 2) {
        max-width: 149.33px;
      }
    }
  }
}
