.recomendation {
  &__section {
    color: #fff;
    padding-bottom: 26px;
    position: relative;
    height: 520px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    margin-top: auto;
    height: 100%;
    margin-left: 173px;
  }
  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    max-width: 531px;
    width: 100%;
  }
  &__action {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }

  @media screen and (min-width: 1200px) {
    &__section {
      background: url('../../../assets/gasStation.webp');
      background-position: right bottom;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  @media screen and (max-width: 1399px) {
    &__wrapper {
      margin-left: 60px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    &__section {
      background-color: #1a1a1a;
    }
    &__image {
      position: absolute;
      right: 0;
      max-width: 612px;
      width: 100%;
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    &__content {
      z-index: 2;
    }
    &__wrapper {
      margin-left: 60px;
    }
  }
  @media screen and (max-width: 991px) {
    &__section {
      background-color: #1a1a1a;
      height: auto;
      padding-bottom: 63px;
    }
    &__wrapper {
      flex-direction: column;
      margin-left: 40px;
      align-items: flex-start;
      padding-top: 80px;
    }
    &__content {
      width: 100%;
      max-width: unset;
    }
    &__image {
      width: 100%;
      align-items: flex-end;
      justify-content: end;
    }
  }
}
