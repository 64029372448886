.productActions {
  .productStatus {
    border: 1px solid black;
    padding: 16px;
    .productActions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
