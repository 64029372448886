.contentProductCategory__wrapper {
  .productCard__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px 75px;
    margin-bottom: 12px;
    .productCard {
      .img_wrapper {
        height: 248px;
      }
    }
  }
  @media screen and (max-width: 1399px) {
    .productCard__wrapper {
      gap: 12px 25px;
    }
  }
  @media screen and (max-width: 1199px) {
    .productCard__wrapper {
      gap: 12px 31px;
      .productCard {
        .img_wrapper {
          height: 200px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .productCard__wrapper {
      gap: 12px 14px;
      .productCard {
        .img_wrapper {
          height: 220px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .productCard__wrapper {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px 16px;
      .productCard {
        .img_wrapper {
          height: 248px;
        }
      }
    }
  }
}
