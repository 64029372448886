.productSummary {
  display: grid;
  grid-template-columns: minmax(0, 418px) minmax(0, 742px);
  column-gap: 80px;
  margin-bottom: 100px;
  @media screen and (max-width: 1399px) {
    column-gap: 40px;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: minmax(0, 280px) minmax(0, 384px);
    column-gap: 24px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
    row-gap: 40px;
  }
  &__content {
    width: 100%;
    .productHeader {
      margin-bottom: 28px;
      .productTitle {
        max-width: 620px;
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .productBrand {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
    .productBrand span {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
    }
    .productBrand img {
      max-height: 17px;
    }
    .productContent {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
    .productSpec__wrapper {
      display: flex;
      flex-flow: row wrap;
      row-gap: 8px;
      .detailSpecification {
        display: flex;
        flex: 0 0 50%;
        column-gap: 8px;
        align-items: center;
      }
    }
    .productSpec {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
    .productSpec .detailSpecification {
      display: flex;
      column-gap: 8px;
      align-items: center;
    }
    .productAction {
      display: flex;
      column-gap: 12px;
    }
  }
}
.productTab {
  margin-bottom: 52px;
  .tab-content {
    border: 1px solid #dee2e6;
    border-top: transparent;
    padding: 40px;
  }
}
.productTags {
  display: flex;
  column-gap: 28px;
  margin-bottom: 80px;
}
.productRecomendation {
  & .__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .divider {
    margin-bottom: 32px;
  }
  & .__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    .productCard {
      max-width: 248px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    & .__content {
      .productCard {
        max-width: 220px;
      }
      & > *:nth-last-child(-n + 1) {
        display: none;
      }
    }
  }
}
