.footer__section {
  background: #212529;
  color: #fff;
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .footer__info {
    padding-top: 68px;
    padding-bottom: 84px;
    // // height: 380px;
  }
  .footer__left {
    max-width: 288px;
    width: 100%;
  }
  .contact__wrapper {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }
  .linkFooter__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
  }
  .copyright {
    background: #29abe1;
    font-size: 14px;
    line-height: 21px;
  }
  @media screen and (max-width: 991px) {
    .footer__right {
      display: flex;
    }
  }
}
